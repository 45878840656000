
  import { Component } from 'vue-property-decorator'
  import { TimelineBase } from '@/components/core/view/TimelineBase'
  import { VImg, VRow } from 'vuetify/lib/components'

@Component({
  components: {
    VRow,
    VImg,
  },
})
  export default class GTimelineItemPhoto extends TimelineBase {
  errorImage = 'https://firebasestorage.googleapis.com/v0/b/dev-petersen-public/o/rrhh%2FNo%20disponiblee.png?alt=media&token=b06ab01b-f2b3-442c-9407-5eb414216858';
  displayError = false;

  errorHandler () {
    this.displayError = true
  }

  render (h) {
    const imgProps = {
      src: !this.displayError ? this.value : this.errorImage,
      maxWidth: 100,
      maxHeight: 100,
      contain: true,
      class: 'image',
    }

    const imgEvents = !this.displayError
      ? {
        on: {
          error: this.errorHandler,
        },
      }
      : {}

    return h(
      'v-row',
      { props: { noGutters: true } },
      [
        h(
          'p',
          {
            class: [
              'text-caption',
              'primary-text',
              'text--darken-4',
              'pa-0',
            ],
          },
          h(
            'span',
            {
              class: 'font-weight-bold blue--text text--darken-3',
            },
            [this.label]
          ),
        ),
        h('v-img', { props: imgProps, ...imgEvents }),
      ]
    )
  }
  }
